import { ISeo } from '@typesApp/seo'
import Head from 'next/head'
import { FC, ReactNode } from 'react'
import useDisableIndexing from '@hooks/useDisableIndexing'
import { PAGE_TYPES_TO_CHECK_INDEXING } from '@constants/common'

interface HeadProps {
  seoData: ISeo
  children?: ReactNode
}

// TODO: Merge into one SEO component
export const HeadComponent: FC<HeadProps> = ({ seoData, children }) => {
  const { shouldDisableIndexing } = useDisableIndexing(PAGE_TYPES_TO_CHECK_INDEXING)

  return (
    <Head>
      <meta name="description" content={seoData.page.metaDescription} />
      <meta name="keywords" content={seoData.page.metaKeyword} />
      {shouldDisableIndexing && <meta name="robots" content="noindex, nofollow" />}
      {children}
      <title>{seoData.page.title}</title>
    </Head>
  )
}
