import React, { FC, memo } from 'react'
import { useRouter } from 'next/router'
import { seoUrlConcat } from '../../utils/url'
import { HeadComponent } from './Head'
import { ISeo } from '@typesApp/seo'
import useCanonicalUrl from '@foundation/hooks/useCanonicalUrl'

interface ActiveComponentProps {
  seoConfig: {
    [x: string]: ISeo
  }
  url: string
  currentLangCode: string
  alternateSeoUrls: Record<string, string>
  props: any
}

const ActiveComponent: FC<ActiveComponentProps> = memo(
  ({ seoConfig, url, currentLangCode, alternateSeoUrls, props }) => {
    const router = useRouter()

    const canonical = useCanonicalUrl(props.locationOrigin, props?.plpCommerce)

    if ((seoConfig && seoConfig[encodeURI(url)]) || (seoConfig && seoConfig['NotFound'])) {
      const seoData = seoConfig[encodeURI(url)] || seoConfig['NotFound']
      const ActiveC = seoData.component

      // Redirect if the seoData has a redirect URL
      if (seoData.redirect && seoData.redirect.trim() !== '') {
        const redirectUrl = seoData.redirect.replace(currentLangCode.toLowerCase(), '')
        router.push(seoUrlConcat(currentLangCode, redirectUrl))
      }

      return (
        <div className="page">
          <HeadComponent seoData={seoData}>
            {canonical && <link rel="canonical" href={canonical} />}

            {alternateSeoUrls &&
              Object.entries(alternateSeoUrls).map(([locale, href]) => {
                if (locale === 'de-de') {
                  return null
                }
                return <link key={locale} rel="alternate" hrefLang={locale} href={href} />
              })}
          </HeadComponent>
          <ActiveC page={seoData.page} {...props} />
        </div>
      )
    } else {
      return null
    }
  }
)

export default ActiveComponent
