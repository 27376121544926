import { IPage } from '@typesApp/cms'
import { replaceLocaleInUrl } from '@utils/locale'
import { t } from 'i18next'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

const useCanonicalUrl = (locationOrigin: string, pageState?: IPage) => {
  const { locale, isLocaleDomain } = useRouter()
  const path = usePathname()

  return useMemo(() => {
    const canonicalSource = pageState?.category?.canonicalurl
      ? pageState?.category?.canonicalurl
      : path.replace(/\/$/, '')
    const canonicalValue = canonicalSource ? canonicalSource : t('InternalError.Msgs.UrlContactLenses')
    const canonicalUrl = replaceLocaleInUrl({
      isLocaleDomain,
      locationOrigin,
      locale,
      href: canonicalValue,
    })
    return `${canonicalUrl}`
  }, [pageState, path, isLocaleDomain, locationOrigin, locale])
}

export default useCanonicalUrl
